/* .radio */
//@media ($xs) { /* .radio $xs */ }
//@media ($sm) { /* .radio $sm */ }
//@media ($md) { /* .radio $md */ }
//@media ($lg) { /* .radio $lg */ }
//@media ($xl) { /* .radio $xl */ }
//@media ($xxl) { /* .radio $xxl */ }

.radio {
  display: flex;
  align-items: center;

  &__input {
    appearance: none;
    border: none;
  }

  &__label {
    font-size: 16px;
    color: #444;
    padding-left: 36px;
    display: inline-block;
    cursor: pointer;
    position: relative;

    &:before {
      content: '';
      display: block;
      width: 22px;
      height: 22px;
      border: 1px solid #D2D4D9;
      background-color: #fff;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: -12px;
      z-index: 1;
    }

    &:after {
      content: '';
      display: block;
      width: 8px;
      height: 8px;
      background-color: #fff;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 8px;
      margin-top: -4px;
      z-index: 2;
      opacity: 0;
    }

    .radio__input:checked + &:before {
      border-color: #FED915;
      background-color: #FED915;
    }

    .radio__input:checked + &:after {
      opacity: 1;
      background: #FAFDFD;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    }
  }
}