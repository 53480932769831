/* .btn */
//@media ($xs) { /* .btn $xs */ }
//@media ($sm) { /* .btn $sm */ }
//@media ($md) { /* .btn $md */ }
@media ($lg) { /* .btn $lg */ }
//@media ($xl) { /* .btn $xl */ }
//@media ($xxl) { /* .btn $xxl */ }

.btn {
  cursor: pointer;
  outline: none;
  background-color: #FED915;
  border: 1px solid #FED915;
  color: #0F1011;
  font-weight: 900;
  font-family: $ff_base;
  padding: 5px 15px;
  border-radius: 12px;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  text-align: center;

  &:hover {
    background-color: transparent;
    text-decoration: none;
  }

  &--full {
    width: 100%;
  }

  &--negative {
    background-color: transparent;
    color: $c_accent;
  }

  &--negative:hover {
    background-color: $c_accent;
    color: #FAFAFA;
  }

  &--blue {
    background-color: $c_brand;
    border-color: $c_brand;
  }
  &--blue:hover {
    color: $c_brand;
  }
  &--blue-negative {
    background-color: transparent;
    border-color: $c_brand;
    color: $c_brand;
  }
  &--blue-negative:hover {
    background-color: $c_brand;
    color: #fff;
  }
  &--red {
    background-color: #FE2E00;
    border-color: #FE2E00;
    color: #fff;
  }
  &--red:hover {
    background-color: rgba(255, 255, 255, .3);
    color: #FE2E00;
  }
  &--red-negative {
    border-color: #FE2E00;
    color: #FE2E00;
    background-color: #fff;
  }
  &--red-negative:hover {
    background-color: #FE2E00;
    color: #fff;
  }
  &--more {
    text-transform: none;
    font-size: 16px;
    font-weight: 900;
    font-family: $ff_base;
    padding: 13px 23px;
    margin-top: 13px;
    width: 100%;
    max-width: 328px;

    @media ($md) {
      width: auto;
    }
  }
}
