/* .advantages-of-viagra */
//@media ($xs) { /* .advantages-of-viagra $xs */ }
//@media ($sm) { /* .advantages-of-viagra $sm */ }
//@media ($md) { /* .advantages-of-viagra $md */ }
//@media ($lg) { /* .advantages-of-viagra $lg */ }
//@media ($xl) { /* .advantages-of-viagra $xl */ }
//@media ($xxl) { /* .advantages-of-viagra $xxl */ }

.advantages-of-viagra {
  padding: 48px 8px;
  color: #202225;

  @media ($lg) {
    padding-top: 96px;
    padding-bottom: 72px;
  }

  &__title {
    font-size: 32px;
    line-height: 36px;
    letter-spacing: -1px;
    margin-bottom: 20px;

    @media ($lg) {
      font-size: 42px;
      line-height: 48px;
    }
  }
  &__title--plus {
    color: #6AA53F;
  }
  &__title--minus {
    color: #FC0D63;
  }
  &__item {
    margin-bottom: 12px;
    position: relative;
    padding-left: 24px;
  }
  &__item:before {
    position: absolute;
    top: 6px;
    left: 6px;
  }
  &__item:before {
    top: 11px;
  }
  &__image {
    @media ($lg) {
      order: 3;
    }
  }
  & > div {
    margin-bottom: 20px;
  }
  & > div:last-child {
    display: flex;
    flex-direction: column;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  img {
    width: 224px;
    margin-bottom: 32px;

    @media ($lg) {
      width: 384px;
    }
  }
}