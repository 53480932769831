/* .tbl */
//@media ($xs) { /* .tbl $xs */ }
//@media ($sm) { /* .tbl $sm */ }
@media ($md) { /* .tbl $md */ }
@media ($lg) { /* .tbl $lg */ }
//@media ($xl) { /* .tbl $xl */ }
//@media ($xxl) { /* .tbl $xxl */ }

.tbl {
  &__thead-th {
    font-family: $ff_fourth;
    font-weight: 700;
    color: #5C6471;
    font-size: 12px;
    line-height: 14px;
    //border-bottom: 1px solid #C3C8D0;
    padding: 13px 8px;
  }

  &__thead-th--order-prod,
  &__thead-th--price-prod {
    font-size: 0;

    @media ($lg) {
      font-size: 12px;
    }
  }

  &__tbody-td {
    padding: 12px;

    @media ($lg) {
      padding: 11px 8px;
    }
  }

  &__tbody-td--package-prod {
    @media ($lg) {
      padding-left: 8px;
    }
  }

  &__tbody-td--package-prod .tbl__tbody-td-inner {
    @media ($lg) {
      display: flex;
      align-items: center;
    }
  }

  //&__tbody-td--order-prod {
  //  padding-right: 0;
  //}

  &__tbody-td--order-prod .btn {
    padding: 12px 11px;

    @media ($lg) {
      padding: 7px 16px;
    }
  }

  &__tbody-td--order-prod .btn:hover .icon:before {
    @include sprite($icon-cart);
  }

  &__tbody-td--order-prod .tbl__tbody-td-inner {
    text-align: right;

    @media ($lg) {
      text-align: center;
    }
  }

  &__tbody-td--price-prod {
    font-weight: 700;
    text-align: left;
  }

  &__tbody-td--price-prod span {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #5C6471;
  }

  &__tbody--prod-updated {
    position: relative;
  }

  &__tbody--prod-updated:before {
    content: "";
    position: absolute;
    top: 2px;
    right: 0;
    bottom: 2px;
    left: 0;
    background: linear-gradient(90deg, rgba(255, 217, 118, 0.2) 1.97%, rgba(255, 234, 181, 0) 97.26%);
    z-index: -1;
    border-radius: 6px;
  }

  &__tbody--prod-updated tr:nth-child(2) td {
    padding-top: 0;
  }

  &__tbody--prod-updated tr:nth-child(2) .tbl__tbody-td-inner {
    margin-top: -2px;
  }
}

.tbl--cart {
  margin-bottom: 20px;
  color: #111D33;

  @media ($md) {
    margin-bottom: 40px;
  }

  table,
  thead,
  tbody,
  tr,
  th,
  td {
    display: block;
  }
  table {
    @media ($md) {
      display: table;
    }
  }
  thead {
    @media ($md) {
      display: table-header-group;
    }
  }
  tbody {
    @media ($md) {
      display: table-row-group;
    }
  }
  tr {
    @media ($md) {
      display: table-row;
    }
  }
  th,
  td {
    @media ($md) {
      display: table-cell;
    }
  }
  .tbl {
    &__thead-th {
      display: none;
      border-bottom: none;

      @media ($md) {
        display: table-cell;
      }
    }
    &__thead-th--package {
      display: block;
      padding-left: 12px;

      @media ($md) {
        display: table-cell;
      }
    }
    &__thead-th--price,
    &__thead-th--subtotal {
      text-align: right;
    }
    &__tbody {
      margin-bottom: 8px;
    }
    &__tbody:nth-child(odd) {
      position: relative;
    }
    &__tbody:nth-child(odd):after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(90deg, rgba(255, 217, 118, 0.2) 1.97%, rgba(255, 234, 181, 0) 97.26%);
      z-index: -1;
      border-radius: 8px;
    }
    &__tbody--shipping-upgrade .tbl__tbody-tr {
      margin-top: -10px;
      padding-bottom: 10px; 
    }
    &__tbody-td {
      border-bottom: none;
    }
    &__tbody-tr {
      margin-bottom: 8px;
    }
    &__tbody-tr:first-child {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      position: relative;
      padding: 12px 8px 12px 73px;

      @media ($md) {
        display: table-row;
        background-color: transparent;
      }

      .tbl__tbody-td {
        padding: 0;

        @media ($md) {
          // background-color: #f3f4f5;
          padding: 18px 8px;
        }
      }
      
      .tbl__tbody-td--package {
        width: 100%;
        padding-right: 40px;
        margin-bottom: 4px;
        color: #202225;

        @media ($md) {
          width: auto;
          padding-left: 24px;
          color: #0F1011;
        }
      }
      // .tbl__tbody-td--product {
      //   font-weight: 700;

      //   @media ($md) {
      //     border-radius: 12px 0 0 12px;
      //   }
      // }
      .tbl__tbody-td--package {
        text-align: left;
        font-weight: 700;
      }
      .tbl__tbody-td--package .product-description {
        display: block;
        margin: 4px 0;
        font-weight: 400;

        @media ($lg) {
          color: #5C6471;
          font-size: 14px;
          line-height: 20px;
        }
      }
      .tbl__tbody-td--package .tbl__tbody-td-inner {
        display: flex;
        align-items: center;
      }
      .tbl__tbody-td--package img {
        position: absolute;
        top: 12px;
        left: 8px;
        width: 49px;

        @media ($md) {
          position: static;
          margin-right: 10px;
          width: 64px;
          height: auto;
        }
      }
      .tbl__tbody-td--price,
      .tbl__tbody-td--subtotal {
        @media ($md) {
          text-align: right;
        }
      }
      .tbl__tbody-td--subtotal {
        margin-left: auto;
        font-weight: 700;
      }
      .tbl__tbody-td--remove {
        position: absolute;
        top: 25px;
        right: 25px;
        
        @media ($md) {
          position: static;
          border-radius: 0 12px 12px 0;
        }
      }
      .tbl__tbody-td--remove button {
        border: none;
        display: block;
        background-color: transparent;
        cursor: pointer;
        padding: 0;
        font-family: $ff_second;
        font-weight: 400;
        color: #8C97AC;

        @media ($md) {
          margin: 0 auto;
        }
      }
      .tbl__tbody-td--remove button:hover {
        color: $c_text;
      }
      .tbl__tbody-td--remove .icon {
        display: block;
        font-size: 0;
      }
    }
    &__tbody-tr:last-child {
      margin-bottom: 0;
    }
    &__tbody-td--upgrade {
      padding: 0 12px;
      font-size: 10px;
      line-height: 12px;
      color: #FC340D;
      font-weight: 700;
      
      @media ($md) {
        padding: 0 16px 8px;
        position: relative;
        top: -8px;
      }
    }
    &__tbody-td--upgrade span {
      white-space: nowrap;  
    }
    &__tbody-td--upgrade a {
      position: relative;
      font-family: $ff_base;
      font-weight: 700;
      color: #FF6915;

      @media ($md) {
        display: inline-block;
      }
    }
    &__tbody-td--upgrade a:before {
      margin-right: 4px;
      position: relative;
      top: -2px;
    //   position: absolute;
    //   top: 2px;
    //   left: -17px;

    //   @media ($md) {
    //     position: static;
    //     margin-right: 4px;
    //   }
    }
  }
}

.tbl--method {
  margin-bottom: 43px;
  color: #111D33;

  @media ($md) {
    margin-bottom: 24px;
  }

  .tbl__tbody-tr {
    position: relative;
  }

  .tbl__tbody-tr:first-child:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(90deg, rgba(255, 217, 118, 0.2) 1.97%, rgba(255, 234, 181, 0) 97.26%);
    border-radius: 6px;
    z-index: -1;
  }

  .tbl__thead-th,
  .tbl__tbody-td {
    border-bottom: none;
    padding: 13px 14px;
  }
  // .tbl__tbody-td {
  //   padding: 0 0 8px;

  //   @media ($md) {
  //     padding-bottom: 4px;
  //   }
  // }
  .tbl__tbody-td .tbl__tbody-td-inner {
    // background-color: #F3F4F5;
    padding: 14px 8px;
    font-weight: 700;

    @media ($md) {
      padding: 16px 8px;
    }
  }

  .tbl__tbody-td--price {
    white-space: nowrap;
  }

  .radio__label {
    color: #0F1011;
  }
}

.shipping-footer {
  font-family: $ff_base;
  font-weight: bold;
  margin-bottom: 40px;

  &__inner {
    background-color: $c_brand;
    border-radius: 8px;
    padding: 16px;
    display: flex;
    flex-direction: column-reverse;

    @media ($md) {
      flex-direction: row;
    }
  }
  &__desc {
    @media ($md) {
      width: 50%;
      margin-right: 15px;
    }
  }
  &__desc-text {
    display: flex;

    @media ($xl) {
      align-items: center;
    }
  }
  &__desc-text .icon:before {
    margin-right: 13px;
  }
  &__input-code {
    width: 100%;
    height: 48px;
    outline: none;
    border-radius: 4px;
    border: 1px solid #C3C8D0;
    padding: 0 140px 0 16px;
    letter-spacing: 0.5px;
    background-color: #fff;
    color: #BAC0C9;
  }
  &__submit {
    position: absolute;
    top: 0;
    right: 0;
    border: 1px solid #C3C8D0;
    height: 100%;
    font-weight: 700;
    background-color: #fff;
    cursor: pointer;
    padding: 0 42px 0 32px;
    border-radius: 4px;
    font-family: $ff_second;

    &:hover {
      background-color: #f0f0f0;
    }
  }
  &__left {
    @media ($xl) {
      max-width: 353px;
    }
  }
  &__right {
    text-align: center;
    font-family: $ff_second, sans-serif;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -1px;

    @media ($xl) {
      flex-grow: 1;
      padding: 0 10px;
    }
  }
  &__block-coupon {
    margin-bottom: 15px;

    @media ($md) {
      margin-bottom: 0;
      flex-grow: 1;
    }

    @media ($xl) {
      display: flex;
      align-items: center;
    }
  }
  &__desc-text span {
    font-size: 14px;
    color: #7C828B;
    font-weight: 400;
    flex-grow: 1;
    line-height: 20px;
  }
  &__block-code {
    position: relative;
    margin-bottom: 15px;

    @media ($xl) {
      margin-bottom: 0;
    }
  }
}

.tbl--product {
  margin-bottom: 40px;
  
  @media ($lg) {
    margin-bottom: 56px;
  }

  .tbl__tbody-td--savings-prod {
    font-weight: 700;
    color: #EB2655;
  }

  .tbl__tbody-td--price-prod,
  .tbl__tbody-td--per-pill-prod{
    color: #111D33;
  }

  .btn {
    width: 48px;
    height: 48px;
    border-radius: 12px;
    background: radial-gradient(91.48% 91.48% at 43.31% 8.52%, #FFE248 0%, #F97801 100%);
    border: none;

    @media ($lg) {
      width: auto;
      height: auto;
      background: #FED915;
      border: 1px solid #FED915;
    }
  }

  .btn:hover {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);

    @media ($lg) {
      box-shadow: none;
      background: none;
    }
  }

  .btn .icon {
    position: relative;
    left: -2px;
  }
}
