body {
  background-color: #fff;
  font-family: $ff_base;
  font-size: 18px;
  line-height: 1.333;
  color: #37445E;
}

h1, h2, h3 {
  font-weight: 700;
  font-family: $ff_third;
  letter-spacing: -1px;
  color: #0F1011;
}

h1 {
  font-size: 32px;
  line-height: 36px;
  letter-spacing: -1px;
  margin: 0 0 16px;
  
  @media ($lg) {
    font-size: 42px;
    line-height: 48px;
  }
}

h2 {
  font-size: 28px;
  line-height: 32px;
  margin: 0 0 24px;
}

h3 {
  font-size: 20px;
  line-height: 24px;
  margin: 0 0 12px;
}

h4 {
  font-size: 20px;
  line-height: 24px;
  margin: 0 0 12px;
}

p {
  margin: 0 0 22px;
}

small {
  font-size: 14px;
  line-height: 20px;
}

a {
  color: #0881A9;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

img {
  display: inline-block;
  max-width: 100%;
  vertical-align: middle;
}

svg {
  display: inline-block;
  vertical-align: middle;
}

table {
  width: 100%;
  border-collapse: collapse;
}

td,
th {
  border: none;
  padding: 5px;
  text-align: center;

  &:first-child {
    text-align: left;
    padding-left: 16px;
  }
}