/* .features-of-products */
//@media ($xs) { /* .features-of-products $xs */ }
//@media ($sm) { /* .features-of-products $sm */ }
//@media ($md) { /* .features-of-products $md */ }
//@media ($lg) { /* .features-of-products $lg */ }
//@media ($xl) { /* .features-of-products $xl */ }
//@media ($xxl) { /* .features-of-products $xxl */ }

.features-of-products {
  padding-top: 48px;
  padding-bottom: 48px;
  flex-wrap: nowrap;
  overflow-x: auto;

  @media ($lg) {
    flex-wrap: wrap;
    padding-top: 82px;
    padding-bottom: 96px;
  }

  &__item {
    max-width: 252px;
    text-align: center;

    @media ($lg) {
      max-width: none;
      margin-bottom: 56px;
    }
  }

  &__text {
    font-size: 14px;
    line-height: 20px;
    color: #5C6471;
  }

  img {
    margin-bottom: 18px;
  }
}