// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$icon-advantages-minus-name: 'icon-advantages-minus';
$icon-advantages-minus-x: 48px;
$icon-advantages-minus-y: 33px;
$icon-advantages-minus-offset-x: -48px;
$icon-advantages-minus-offset-y: -33px;
$icon-advantages-minus-width: 10px;
$icon-advantages-minus-height: 2px;
$icon-advantages-minus-total-width: 75px;
$icon-advantages-minus-total-height: 67px;
$icon-advantages-minus-image: 'sprite.png';
$icon-advantages-minus: (48px, 33px, -48px, -33px, 10px, 2px, 75px, 67px, 'sprite.png', 'icon-advantages-minus', );
$icon-advantages-plus-name: 'icon-advantages-plus';
$icon-advantages-plus-x: 65px;
$icon-advantages-plus-y: 38px;
$icon-advantages-plus-offset-x: -65px;
$icon-advantages-plus-offset-y: -38px;
$icon-advantages-plus-width: 10px;
$icon-advantages-plus-height: 10px;
$icon-advantages-plus-total-width: 75px;
$icon-advantages-plus-total-height: 67px;
$icon-advantages-plus-image: 'sprite.png';
$icon-advantages-plus: (65px, 38px, -65px, -38px, 10px, 10px, 75px, 67px, 'sprite.png', 'icon-advantages-plus', );
$icon-arr-choose-dos-name: 'icon-arr-choose-dos';
$icon-arr-choose-dos-x: 0px;
$icon-arr-choose-dos-y: 55px;
$icon-arr-choose-dos-offset-x: 0px;
$icon-arr-choose-dos-offset-y: -55px;
$icon-arr-choose-dos-width: 7px;
$icon-arr-choose-dos-height: 12px;
$icon-arr-choose-dos-total-width: 75px;
$icon-arr-choose-dos-total-height: 67px;
$icon-arr-choose-dos-image: 'sprite.png';
$icon-arr-choose-dos: (0px, 55px, 0px, -55px, 7px, 12px, 75px, 67px, 'sprite.png', 'icon-arr-choose-dos', );
$icon-arrow-dropdown-name: 'icon-arrow-dropdown';
$icon-arrow-dropdown-x: 43px;
$icon-arrow-dropdown-y: 40px;
$icon-arrow-dropdown-offset-x: -43px;
$icon-arrow-dropdown-offset-y: -40px;
$icon-arrow-dropdown-width: 12px;
$icon-arrow-dropdown-height: 7px;
$icon-arrow-dropdown-total-width: 75px;
$icon-arrow-dropdown-total-height: 67px;
$icon-arrow-dropdown-image: 'sprite.png';
$icon-arrow-dropdown: (43px, 40px, -43px, -40px, 12px, 7px, 75px, 67px, 'sprite.png', 'icon-arrow-dropdown', );
$icon-arrow-select-name: 'icon-arrow-select';
$icon-arrow-select-x: 65px;
$icon-arrow-select-y: 49px;
$icon-arrow-select-offset-x: -65px;
$icon-arrow-select-offset-y: -49px;
$icon-arrow-select-width: 8px;
$icon-arrow-select-height: 5px;
$icon-arrow-select-total-width: 75px;
$icon-arrow-select-total-height: 67px;
$icon-arrow-select-image: 'sprite.png';
$icon-arrow-select: (65px, 49px, -65px, -49px, 8px, 5px, 75px, 67px, 'sprite.png', 'icon-arrow-select', );
$icon-bonuses-name: 'icon-bonuses';
$icon-bonuses-x: 48px;
$icon-bonuses-y: 17px;
$icon-bonuses-offset-x: -48px;
$icon-bonuses-offset-y: -17px;
$icon-bonuses-width: 16px;
$icon-bonuses-height: 15px;
$icon-bonuses-total-width: 75px;
$icon-bonuses-total-height: 67px;
$icon-bonuses-image: 'sprite.png';
$icon-bonuses: (48px, 17px, -48px, -17px, 16px, 15px, 75px, 67px, 'sprite.png', 'icon-bonuses', );
$icon-cart-white-name: 'icon-cart-white';
$icon-cart-white-x: 0px;
$icon-cart-white-y: 0px;
$icon-cart-white-offset-x: 0px;
$icon-cart-white-offset-y: 0px;
$icon-cart-white-width: 23px;
$icon-cart-white-height: 22px;
$icon-cart-white-total-width: 75px;
$icon-cart-white-total-height: 67px;
$icon-cart-white-image: 'sprite.png';
$icon-cart-white: (0px, 0px, 0px, 0px, 23px, 22px, 75px, 67px, 'sprite.png', 'icon-cart-white', );
$icon-cart-name: 'icon-cart';
$icon-cart-x: 24px;
$icon-cart-y: 0px;
$icon-cart-offset-x: -24px;
$icon-cart-offset-y: 0px;
$icon-cart-width: 23px;
$icon-cart-height: 22px;
$icon-cart-total-width: 75px;
$icon-cart-total-height: 67px;
$icon-cart-image: 'sprite.png';
$icon-cart: (24px, 0px, -24px, 0px, 23px, 22px, 75px, 67px, 'sprite.png', 'icon-cart', );
$icon-hamburger-name: 'icon-hamburger';
$icon-hamburger-x: 0px;
$icon-hamburger-y: 40px;
$icon-hamburger-offset-x: 0px;
$icon-hamburger-offset-y: -40px;
$icon-hamburger-width: 18px;
$icon-hamburger-height: 14px;
$icon-hamburger-total-width: 75px;
$icon-hamburger-total-height: 67px;
$icon-hamburger-image: 'sprite.png';
$icon-hamburger: (0px, 40px, 0px, -40px, 18px, 14px, 75px, 67px, 'sprite.png', 'icon-hamburger', );
$icon-minus-name: 'icon-minus';
$icon-minus-x: 48px;
$icon-minus-y: 36px;
$icon-minus-offset-x: -48px;
$icon-minus-offset-y: -36px;
$icon-minus-width: 10px;
$icon-minus-height: 2px;
$icon-minus-total-width: 75px;
$icon-minus-total-height: 67px;
$icon-minus-image: 'sprite.png';
$icon-minus: (48px, 36px, -48px, -36px, 10px, 2px, 75px, 67px, 'sprite.png', 'icon-minus', );
$icon-plus-name: 'icon-plus';
$icon-plus-x: 32px;
$icon-plus-y: 40px;
$icon-plus-offset-x: -32px;
$icon-plus-offset-y: -40px;
$icon-plus-width: 10px;
$icon-plus-height: 10px;
$icon-plus-total-width: 75px;
$icon-plus-total-height: 67px;
$icon-plus-image: 'sprite.png';
$icon-plus: (32px, 40px, -32px, -40px, 10px, 10px, 75px, 67px, 'sprite.png', 'icon-plus', );
$icon-remove-name: 'icon-remove';
$icon-remove-x: 0px;
$icon-remove-y: 23px;
$icon-remove-offset-x: 0px;
$icon-remove-offset-y: -23px;
$icon-remove-width: 16px;
$icon-remove-height: 16px;
$icon-remove-total-width: 75px;
$icon-remove-total-height: 67px;
$icon-remove-image: 'sprite.png';
$icon-remove: (0px, 23px, 0px, -23px, 16px, 16px, 75px, 67px, 'sprite.png', 'icon-remove', );
$icon-select-marker-disable-name: 'icon-select-marker-disable';
$icon-select-marker-disable-x: 34px;
$icon-select-marker-disable-y: 23px;
$icon-select-marker-disable-offset-x: -34px;
$icon-select-marker-disable-offset-y: -23px;
$icon-select-marker-disable-width: 12px;
$icon-select-marker-disable-height: 12px;
$icon-select-marker-disable-total-width: 75px;
$icon-select-marker-disable-total-height: 67px;
$icon-select-marker-disable-image: 'sprite.png';
$icon-select-marker-disable: (34px, 23px, -34px, -23px, 12px, 12px, 75px, 67px, 'sprite.png', 'icon-select-marker-disable', );
$icon-select-marker-name: 'icon-select-marker';
$icon-select-marker-x: 19px;
$icon-select-marker-y: 40px;
$icon-select-marker-offset-x: -19px;
$icon-select-marker-offset-y: -40px;
$icon-select-marker-width: 12px;
$icon-select-marker-height: 12px;
$icon-select-marker-total-width: 75px;
$icon-select-marker-total-height: 67px;
$icon-select-marker-image: 'sprite.png';
$icon-select-marker: (19px, 40px, -19px, -40px, 12px, 12px, 75px, 67px, 'sprite.png', 'icon-select-marker', );
$icon-slider-next-name: 'icon-slider-next';
$icon-slider-next-x: 65px;
$icon-slider-next-y: 0px;
$icon-slider-next-offset-x: -65px;
$icon-slider-next-offset-y: 0px;
$icon-slider-next-width: 10px;
$icon-slider-next-height: 18px;
$icon-slider-next-total-width: 75px;
$icon-slider-next-total-height: 67px;
$icon-slider-next-image: 'sprite.png';
$icon-slider-next: (65px, 0px, -65px, 0px, 10px, 18px, 75px, 67px, 'sprite.png', 'icon-slider-next', );
$icon-slider-prev-name: 'icon-slider-prev';
$icon-slider-prev-x: 65px;
$icon-slider-prev-y: 19px;
$icon-slider-prev-offset-x: -65px;
$icon-slider-prev-offset-y: -19px;
$icon-slider-prev-width: 10px;
$icon-slider-prev-height: 18px;
$icon-slider-prev-total-width: 75px;
$icon-slider-prev-total-height: 67px;
$icon-slider-prev-image: 'sprite.png';
$icon-slider-prev: (65px, 19px, -65px, -19px, 10px, 18px, 75px, 67px, 'sprite.png', 'icon-slider-prev', );
$icon-times-name: 'icon-times';
$icon-times-x: 17px;
$icon-times-y: 23px;
$icon-times-offset-x: -17px;
$icon-times-offset-y: -23px;
$icon-times-width: 16px;
$icon-times-height: 16px;
$icon-times-total-width: 75px;
$icon-times-total-height: 67px;
$icon-times-image: 'sprite.png';
$icon-times: (17px, 23px, -17px, -23px, 16px, 16px, 75px, 67px, 'sprite.png', 'icon-times', );
$icon-upgrade-name: 'icon-upgrade';
$icon-upgrade-x: 48px;
$icon-upgrade-y: 0px;
$icon-upgrade-offset-x: -48px;
$icon-upgrade-offset-y: 0px;
$icon-upgrade-width: 16px;
$icon-upgrade-height: 16px;
$icon-upgrade-total-width: 75px;
$icon-upgrade-total-height: 67px;
$icon-upgrade-image: 'sprite.png';
$icon-upgrade: (48px, 0px, -48px, 0px, 16px, 16px, 75px, 67px, 'sprite.png', 'icon-upgrade', );
$spritesheet-width: 75px;
$spritesheet-height: 67px;
$spritesheet-image: 'sprite.png';
$spritesheet-sprites: ($icon-advantages-minus, $icon-advantages-plus, $icon-arr-choose-dos, $icon-arrow-dropdown, $icon-arrow-select, $icon-bonuses, $icon-cart-white, $icon-cart, $icon-hamburger, $icon-minus, $icon-plus, $icon-remove, $icon-select-marker-disable, $icon-select-marker, $icon-slider-next, $icon-slider-prev, $icon-times, $icon-upgrade, );
$spritesheet: (75px, 67px, 'sprite.png', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
