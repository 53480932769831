.bonuses {
   margin-bottom: 48px;

  &__item {
    padding: 32px 100px 36px 24px;
    margin-bottom: 12px;
    border-radius: 12px;
    position: relative;
    overflow: hidden;

    @media ($lg) {
      padding-right: 157px;
    }
  }

  &__item:last-child {
    margin-bottom: 0;
  }

  &__item:after {
    content: "";
    position: absolute;
    max-height: 100%;

    @media ($lg) {
      max-height: none;
    }
  }

  &__title {
    font-size: 20px;
    line-height: 24px;
    font-family: $ff_third;
    margin-bottom: 8px;
  }

  &__text {
    font-size: 14px;
    line-height: 20px;
  }

  &__item--free-shipping {
    background: #FB9011;
    min-height: 160px;
  }

  &__item--free-shipping:after {
    width: 132px;
    height: 160px;
    background: url("../img/bonus-free-shipping.png") no-repeat 0 0;
    bottom: 0;
    right: 0;
    
    @media ($lg) {
      width: 214px;
      transform: rotate(52deg);
      height: 208px;
      bottom: auto;
      top: 0;
      right: -34px;
    }
  }
  
  &__item--free-shipping .bonuses__title,
  &__item--bonus-pills .bonuses__title {
    color: #fff;
  }
  
  &__item--free-shipping .bonuses__text,
  &__item--bonus-pills .bonuses__text {
    color: #FBFBFBCC;
  }
  
  &__item--secure-ordering {
    background-color: #49413E;
    color: #fff;
    min-height: 176px;
  }

  &__item--secure-ordering:after {
    width: 108px;
    height: 144px;
    background: url("../img/bonus-secure-ordering.png") no-repeat 0 100%;
    bottom: 15px;
    right: 0;
    
    @media ($lg) {
      width: 130px;
      right: 6px;
      bottom: auto;
      top: 15px;
    }
  }
  
  //&__item--secure-ordering .bonuses__title,
  //&__item--secure-ordering .bonuses__text {
  //  color: #000;
  //}

  &__item--bonus-pills {
    background-color: #FFCD1C;
    min-height: 160px;
  }

  &__item--bonus-pills .bonuses__title {
    color: #0F1011;
  }

  &__item--bonus-pills .bonuses__text {
    color: #5C6471;
  }

  &__item--bonus-pills:after {
    width: 110px;
    height: 148px;
    background: url("../img/bonus-pills.png") no-repeat 0 100%;
    bottom: 4px;
    right: 0;
    
    @media ($lg) {
      width: 144px;
      right: 10px;
      bottom: auto;
      top: 7px;
    }
  }
}