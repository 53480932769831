/* .custom-list */
//@media ($xs) { /* .custom-list $xs */ }
//@media ($sm) { /* .custom-list $sm */ }
//@media ($md) { /* .custom-list $md */ }
//@media ($lg) { /* .custom-list $lg */ }
//@media ($xl) { /* .custom-list $xl */ }
//@media ($xxl) { /* .custom-list $xxl */ }

.custom-list {
  padding: 0 0 0 24px;
  margin: 0 0 28px;
  list-style-type: none;

  .shopping-page & {
    margin-bottom: 40px;
    color: #202225;
  }

  &__item {
    position: relative;
    margin-bottom: 16px;
  }

  &__item--disable {
    color: #BAC0C9;
  }

  &__item:last-child {
    margin-bottom: 0;
  }

  &__item:before {
    content: '';
    position: absolute;
    top: 8px;
    left: -24px;
  }
}