/* .product-desc */
//@media ($xs) { /* .product-desc $xs */ }
//@media ($sm) { /* .product-desc $sm */ }
//@media ($md) { /* .product-desc $md */ }
// @media ($lg) { /* .product-desc $lg */ }
//@media ($xl) { /* .product-desc $xl */ }
//@media ($xxl) { /* .product-desc $xxl */ }

.product-desc {
  margin-bottom: 40px;
  color: #202225;

  &__text {
    @media ($lg) {
      margin-bottom: 0;
    }
  }
}