/* .tabs */
//@media ($xs) { /* .tabs $xs */ }
//@media ($sm) { /* .tabs $sm */ }
//@media ($md) { /* .tabs $md */ }
@media ($lg) { /* .tabs $lg */ }
//@media ($xl) { /* .tabs $xl */ }
//@media ($xxl) { /* .tabs $xxl */ }

.tabs {
    display: block;

    .tabs--product &__nav {
        display: flex;
        //overflow-x: auto;
        flex-wrap: wrap;
        margin-bottom: 12px;

        @media ($lg) {
            margin-bottom: 8px;
        }
    }

    .payment-info &__nav {
        margin-bottom: 7px;
    }

    &__radio {
        display: none;
    }

    &__label {
        cursor: pointer;
        display: inline-block;
        white-space: nowrap;
        font-family: $ff_second;
        font-size: 17px;
        line-height: 20px;
        font-weight: 700;
        padding: 9px 16px;
        color: #5C6471;
        border: 1px solid #FAFAFA;
        background-color: #FAFAFA;
        border-radius: 20px;
        margin-right: 8px;
        margin-bottom: 12px;

        &:hover {
            color: $c_text;
            background-color: #EBEBEB;
            border-color: #EBEBEB;
        }
    }

    .tabs__radio:checked + &__label {
        color: #0F1011;
        background-color: #FED915;
        border-color: #FED915;
    }

    .tabs__radio:checked + &__label:hover {
        background-color: #fff;
    }

    &__item {
        display: none;
        //padding-top: 16px;
        //padding-bottom: 20px;
    }
}

@media ($xl) {
    .tabs__item-credit-card .form__row:last-child {
        position: absolute;
        top: 167px;
        right: 18px;
        display: flex;
        flex-direction: column;
        width: 159px;
        margin-bottom: 0;
    }

    .tabs__item-credit-card .form__row:last-child .form__field {
        max-width: 136px;
        min-width: 136px;
        margin-bottom: 16px;
    }

    .tabs__item-credit-card .form__row:last-child .form__desc {
        padding-left: 3px;
    }

    .shopping-page__paiment-right .tabs__item {
        padding-top: 16px;
    }

    .tabs__item-credit-card {
        background: url("../img/card.png") no-repeat 0 0;
        position: relative;
        left: -20px;
        margin-right: -20px;
        padding: 75px 244px 30px 68px;
    }

    .tabs__item-credit-card .form__row {
        margin-bottom: 24px;
    }

    .tabs__item-credit-card .form__field {
        margin-bottom: 0;
    }

    .tabs__item-credit-card .form__error {
        position: absolute;
        top: 54px;
        left: 8px;
    }

    .tabs__item-credit-card .form__row-label {
        margin-bottom: 10px;
    }

    .tabs__item-credit-card .form__row:last-child .form__desc {
        padding-top: 0;
    }
}