/* .description-help */
//@media ($xs) { /* .description-help $xs */ }
//@media ($sm) { /* .description-help $sm */ }
//@media ($md) { /* .description-help $md */ }
//@media ($lg) { /* .description-help $lg */ }
//@media ($xl) { /* .description-help $xl */ }
//@media ($xxl) { /* .description-help $xxl */ }

.description-help {
  color: #202225;

  @media ($lg) {
    padding-bottom: 96px;
  }

  h2 {
    font-size: 32px;
    line-height: 36px;
    letter-spacing: -1px;
    color: #0F1011;

    @media ($lg) {
      font-size: 42px;
      line-height: 48px;
      margin-bottom: 32px;
    }
  }

  img {
    width: 245px;
    border: 2px solid #fff;
    border-radius: 10px;
    box-shadow: 0px 50.6198px 40.4959px rgba(0, 0, 0, 0.09), 0px 11.3066px 9.04528px rgba(0, 0, 0, 0.0536497), 0px 3.36627px 2.69302px rgba(0, 0, 0, 0.0363503);
    margin-bottom: 48px;

    @media ($lg) {
      width: 484px;
      box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.09), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0536497), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0363503);
      margin-bottom: 0;
      border-radius: 20px;
      border: 4px solid #fff;
    }

    @media ($xxl) {
      margin-top: -133px;
    }
  }
}