$h_mob-view: 72px;
//$h_mob-ctrl: 156px;

/*header*/
//@media ($xs) { /*header $xs*/ }
//@media ($sm) { /*header $sm*/ }
//@media ($md) { /*header $md*/ }
@media ($lg) { /*header $lg*/ }
//@media ($xl) { /*header $xl*/ }
//@media ($xxl) { /*header $xxl*/ }

.header {
  //@media ($lg) {
  //
  //}

  &--home {
    background: url("../img/bg_header.jpg") no-repeat 50% 50%;
    background-size: cover;

    @media ($lg) {
      background-color: #3E3633;
      background-position: 50% 70px;
    }
  }

  &__top-line {
    @media ($lg) {
      min-height: 148px;
      display: flex;
      align-items: center;
      position: relative;
      padding-bottom: 30px;
      margin-bottom: -30px;
      background-color: #3E3632;
      z-index: -1;
    }
  }

  .header--home &__top-line {
    @media ($lg) {
      min-height: 118px;
      padding-bottom: 0;
      margin-bottom: 0;

      background-color: transparent;
    }
  }

  //.header--home &__top-line:before {
  .header--home &__body {
    @media ($lg) {
      position: relative;
    }
  }
  .header--home &__body:before {
    @media ($lg) {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: -moz-linear-gradient(top, #3D3532 0%, #3D3532 68%, rgba(2,143,208,0) 100%);
      background: -webkit-linear-gradient(top, #3D3532 0%, #3D3532 68%, rgba(2,143,208,0) 100%);
      background: linear-gradient(to bottom, #3D3532 0%, #3D3532 68%, rgba(2,143,208,0) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3D3532', endColorstr='#00028fd0',GradientType=0 );
      z-index: -1;
    }
  }

  &__top-line > div {
    position: relative;
    z-index: 2;
  }

  &__mob-top-line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 8px;
    height: $h_mob-view;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #3E3633;
    z-index: 15;
    
    @media ($lg) {
      position: static;
    }
  }

  &__bottom-line {
    padding: 16px 0;

    @media ($lg) {
      padding: 0;
    }
  }

  &__bottom-line-content {
    @media ($lg) {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: center;
      border-radius: 8px;
      background-color: #FFCD1C;
      padding: 0 40px;
    }
  }

  &__body {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #fff;
    transform: translateX(-100%);
    transition: all ease-in 300ms;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 20;
    max-width: 480px;

    @media ($lg) {
      position: static;
      transform: translateX(0);
      background-color: transparent;
      overflow-y: visible;
      overflow-x: visible;
      max-width: none;
    }
  }

  &__body.opened {
    transform: translateX(0);
  }

  &__open-menu {
    background: rgba(0, 0, 0, .2);
    border: none;
    cursor: pointer;
    outline: none;
    display: inline-block;
    width: 48px;
    height: 48px;
    border-radius: 24px;
  }

  &__open-menu:hover {
    opacity: .7;
  }

  &__close-menu {
    border: none;
    background-color: rgba(0, 0, 0, .2);
    cursor: pointer;
    transition: all ease-in-out 500ms;
    padding: 0;
    width: 48px;
    height: 48px;
    border-radius: 24px;
    position: absolute;
    top: 13px;
    left: 16px;
  }

  &__close-menu:hover {
    transform: rotate(360deg);
  }

  &__ctrl-select {
    padding: 20px 16px 20px 95px;
  }

  &__logo svg {
    height: 44px;
  }

  &__logo img {
    @media ($lg) {
      width: 293px;
      height: auto;
      margin-left: 40px;
    }
  }

  &__open-cart {
    background: linear-gradient(50.26deg, rgba(255, 233, 116, 0.8) 12.82%, rgba(229, 236, 249, 0) 86.2%), #FFFFFF;
    box-shadow: 0px 4px 16px #EEAC01;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    border-radius: 24px;
    text-decoration: none;
  }

  &__open-cart:hover {
    opacity: .7;
  }

  &__open-cart:before {
    position: relative;
    left: -2px;
  }

  &__top-line-content {
    flex-direction: column-reverse;
    background-color: #3E3633;
    //margin-bottom: 40px;

    @media ($lg) {
      flex-direction: row;
      align-items: center;
      background-color: transparent;
      margin-bottom: 0;
    }
  }

  &__top-line-controller {
    @media ($lg) {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-end;
    }
  }

  &__mob-menu-ctrl {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: $h_mob-view;
    background-color: $c_brand;
    padding-left: 29px;
    padding-right: 16px;

    @media ($lg) {
      justify-content: flex-end;
      background-color: transparent;
      height: 60px;
    }
  }

  &__banner {
    position: relative;
    color: #fff;
  }
  &__banner:before {
    content: '';
    position: absolute;
    top: 0;
    left: -8px;
    bottom: 0;
    right: -8px;
    z-index: 1;
    background: -moz-linear-gradient(180deg,  rgba(62,54,51,1) 0%, rgba(14,12,12,0.3) 77%, rgba(0,0,0,0.3) 100%);
    background: -webkit-linear-gradient(180deg,  rgba(62,54,51,1) 0%,rgba(14,12,12,0.3) 77%,rgba(0,0,0,0.3) 100%);
    background: linear-gradient(180deg,  rgba(62,54,51,1) 0%,rgba(14,12,12,0.3) 77%,rgba(0,0,0,0.3) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3e3633', endColorstr='#4d000000',GradientType=1 );

    @media ($lg) {
      display: none;
    }
  }
  &__banner-body {
    position: relative;
    padding: 64px 16px 44px;
    z-index: 2;

    @media ($lg) {
      padding-top: 45px;
      padding-left: 98px;
      padding-bottom: 75px;
    }
  }
  &__banner-body .btn {
    width: 100%;
    font-weight: 900;
    font-size: 20px;
    line-height: 24px;
    padding: 19px 16px;
    border-radius: 38px;
    max-width: 328px;

    @media ($lg) {
      width: auto;
      padding: 19px 32px;
    }
  }
  &__banner-title {
    font-family: $ff_second;
    font-weight: 700;
    font-size: 64px;
    line-height: 96px;
    margin: 0;
    color: #fff;

    @media ($lg) {
      font-size: 96px;
      margin-bottom: 16px;
    }
  }
  &__banner-desc {
    color: #FBFBFB;
    opacity: 0.8;
    font-size: 28px;
    line-height: 30px;
    margin-bottom: 42px;
    font-weight: 400;

    @media ($lg) {
      margin-bottom: 31px;
      max-width: 330px;
    }
  }
  &__banner-details {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 12px;

    @media ($lg) {
      margin-bottom: 11px;
    }
  }
  &__banner-dosage {
    margin-right: 7px;
  }
  &__banner-price {
    font-weight: 700;
  }
  &__banner-price:before {
    content: "";
    display: inline-block;
    width: 36px;
    border-bottom: 1px dashed rgba(255, 255, 255, 0.4);
  }
  &__banner-promotion {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 12px;
    padding: 20px;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 12px;
    font-weight: 700;
    backdrop-filter: blur(4px);

    @media ($lg) {
      max-width: 453px;
      margin-bottom: 20px;
    }
  }

  &__banner-promotion a {
    color: #FFE55B;
  }
  &__banner-order {
    text-align: center;

    @media ($lg) {
      text-align: left;
      padding-left: 20px;
    }
  }
}