/* base */
//@media ($xs) { /* base $xs */ }
@media ($sm) { /* base $sm */}
//@media ($md) { /* base $md */ }
@media ($lg) { /* base $lg */}
//@media ($xl) { /* base $xl */ }
//@media ($xxl) { /* base $xxl */ }

* {
  box-sizing: border-box;
}

html {
  height: 100%;
}

main {
  padding-top: 40px;
  // padding-bottom: 40px;

  //@media ($lg) {
  //  padding-bottom: 96px;
  //}
}

.page {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding-top: 72px;
  overflow-x: hidden;

  @media ($lg) {
    padding-top: 0;
  }

  &__main {
    padding-top: 48px;
  }

   &__title {
     color: #202225;
  //   margin-top: 0;
  //   font-family: $ff_second;
  //   margin-bottom: 24px;
   }

  &__title span {
    display: block;
    color: #BAC0C9;
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    letter-spacing: 0;
  }

  &__title--small {
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 8px;

    @media ($lg) {
      margin-bottom: 16px;
    }
  }

  &__slider {
    background: url("../img/bg-slider.jpg")no-repeat 55% 50%;
    background-size: cover;

    @media ($lg) {
      background: none;
      position: relative;
      //padding-top: 115px;
      //padding-bottom: 96px;
    }
  }

  &__slider:before,
  &__slider:after {
    @media ($lg) {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      z-index: -1;
    }
  }

  &__slider:before {
    left: 0;
    right: 50%;
    background: #fdcb38;
    background: -moz-linear-gradient(top,  #fdcb38 0%, #fdca21 100%);
    background: -webkit-linear-gradient(top,  #fdcb38 0%,#fdca21 100%);
    background: linear-gradient(to bottom,  #fdcb38 0%,#fdca21 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fdcb38', endColorstr='#fdca21',GradientType=0 );

  }

  &__slider:after {
    left: 50%;
    right: 0;
    background: #fdcc49;
    background: -moz-linear-gradient(top,  #fdcc49 0%, #ffca2c 100%);
    background: -webkit-linear-gradient(top,  #fdcc49 0%,#ffca2c 100%);
    background: linear-gradient(to bottom,  #fdcc49 0%,#ffca2c 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fdcc49', endColorstr='#ffca2c',GradientType=0 );
  }

  &__slider .row {
    @media ($lg) {
      padding-top: 115px;
      padding-bottom: 96px;
      background: url("../img/bg-slider.jpg")no-repeat 12% 12%;
    }
    @media ($xl) {
      background-position: -68% 12%;
    }
  }

  &__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .7);
    z-index: 15;
    display: none;
  }

  &__backdrop.opened {
    display: block;
  }
}

.error {
  color: #FB540D;
}

.index-page {
  padding-top: 0;
  
  //@media ($lg) {
  //  padding-top: 96px;
  //}
}

.index-page .page__section:first-child {
  padding-top: 48px;

  @media ($lg) {
    padding-top: 96px;
  }
}

.account-page,
.coupon-page,
.subscribe-page {

  input,
  textarea {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 4px;
    border: 1px solid #8B929F;
    background-color: #fff;
    outline: none;
  }

  input {
    height: 56px;
  }

  textarea {
    height: 192px !important;
  }

  input[type="submit"] {
    padding: 6px 30px !important;
    border-radius: 4px !important;
  }

  input[type="submit"]:hover {
    color: #0F1011;
  }

  input[name="vcode"] {
    margin-right: 5px;
  }

  img.vcode {
    margin-right: 5px;
  }

  table,
  thead,
  tbody,
  tfoot,
  tr,
  td {
    display: block;
  }

  table {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;

    @media ($sm) {
      display: table;
    }
  }

  thead {
    @media ($sm) {
      display: table-header-group;
    }
  }

  tbody {
    @media ($sm) {
      display: table-row-group;
    }
  }

  tfoot {
    @media ($sm) {
      display: table-footer-group;
    }
  }

  tr {
    @media ($sm) {
      display: table-row;
    }
  }

  th,
  td {
    text-align: left;
    
    @media ($sm) {
      display: table-cell;
    }
  }

  th a,
  td a {
    white-space: nowrap;
  }

  table input {
    width: 100%;

    @media ($sm) {
      width: auto;
    }
  }

  table input[name="vcode"] {
    width: auto;
  }

  table button,
  table input[type="submit"] {
    display: inline-block;
    background-color: #FED915;
    border: 1px solid #FED915;
    color: #0F1011;
    padding: 6px 11px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    width: auto;
    font-family: $ff_second;
    font-weight: 700;
    text-transform: uppercase;

    &:hover {
      background-color: transparent;
    }
  }

  th,
  td {
    padding: 0;

    @media ($sm) {
      padding: 5px;
      text-align: left;
    }
  }

  th:first-child,
  td:first-child {
    @media ($sm) {
      text-align: right;
    }
  }

  tr {
    margin-bottom: 4px;
  }

  tbody {
    order: 0;
  }

  tfoot {
    order: 1;
  }

  tfoot th:first-child {
    display: none;

    @media ($sm) {
      display: table-cell;
    }
  }

  tfoot th[colspan="2"] {
    display: block;
    text-align: left;

    @media ($sm) {
      padding-left: 31%;
    }
  }
}

table.simple {
  margin: 15px 0;

  thead th,
  thead td {
    font-family: $ff_second;
    text-align: center;
    font-size: 13px;
    line-height: 16px;
    color: #5c6471;
    font-weight: bold;

    &:first-child {
      text-align: left;
    }
  }

  tbody td,
  tbody th {
    border-top: 1px solid #d2d4d9;
    text-align: center;

    &:first-child {
      text-align: left;
    }
  }
}

.bonuses-page .free_pills {
  margin-bottom: 16px;
}
.bonuses-page td {
  text-align: left;
}
.faq-page ul,
.policies-page ul {
  margin-top: 0;
}

.testimonial {
  border-bottom: 1px dotted #BAC0C9;
  padding-bottom: 15px;
  margin-bottom: 15px;

  &:last-child {
    border-bottom: none;
    margin-bottom: 0;
  }

  .test-header {
    text-align: right;
    font-weight: bold;
    margin-bottom: 10px;
  }
}

.contact-page {
  @media ($lg) {
    padding-bottom: 96px;
  }
}

.contact-page strong {
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -1px;
  color: #0881A9;
  font-weight: 700;
  font-family: $ff_third;
}

.shadow-block {
  padding: 32px 24px;
  background: #FFFFFF;
  box-shadow: 0px 100px 100px rgba(19, 33, 53, 0.04), 0px 22.3363px 22.3363px rgba(19, 33, 53, 0.0238443), 0px 6.6501px 6.6501px rgba(19, 33, 53, 0.0161557);
  border-radius: 12px;
}

.product-content {
  @media ($lg) {
    display: flex;
    flex-direction: column;
  }

  .page__title {
    @media ($lg) {
      order: 1;
      margin-bottom: 20px;
    }
  }

  .product-desc {
    @media ($lg) {
      order: 2;
    }
  }

  .tabs {
    @media ($lg) {
      order: 3;
    }
  }

  .about-product {
    @media ($lg) {
      order: 4;
    }
  }
}

.testimonials-page,
.bonuses-page {
  margin-bottom: 40px;

  @media ($lg) {
    margin-bottom: 72px;
  }
}