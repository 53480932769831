/* .support */
//@media ($xs) { /* .support $xs */ }
//@media ($sm) { /* .support $sm */ }
//@media ($md) { /* .support $md */ }
@media ($lg) { /* .support $lg */ }
//@media ($xl) { /* .support $xl */ }
//@media ($xxl) { /* .support $xxl */ }

.support {
  background-color: #2F292B;
  border-radius: 8px;
  height: 60px;
  padding: 0 20px;

  display: flex;
  align-items: center;
  text-decoration: none;
  color: #fff;
  //position: fixed;
  position: absolute;
  bottom: 16px;
  right: 16px;
  z-index: 10;

  &:hover {
    text-decoration: none;
  }

  @media ($lg) {
    //top: 40%;
    //right: 0;
    //margin-bottom: 0;
    color: #0F1011;
    position: static;
    background-color: transparent;
  }

  &__view {
    width: 44px;
    height: 44px;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 8px;
  }

  &__img {
    max-width: 44px;
    max-height: 44px;
  }

  &__body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__text {
    font-weight: 700;
    font-family: $ff_second;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: 0.5px;

    @media ($lg) {
      color: #202225;
    }
  }

  &__status {
    font-size: 10px;
    line-height: 12px;
    padding: 3px 8px;
    background-color: #6AA53F;
    border-radius: 10px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 2px;
    color: rgba(251, 251, 251, 0.8);
  }
}