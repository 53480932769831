/* .product-bonuses */
//@media ($xs) { /* .product-bonuses $xs */ }
//@media ($sm) { /* .product-bonuses $sm */ }
//@media ($md) { /* .product-bonuses $md */ }
//@media ($lg) { /* .product-bonuses $lg */ }
//@media ($xl) { /* .product-bonuses $xl */ }
//@media ($xxl) { /* .product-bonuses $xxl */ }

.product-bonuses {
  &__item {
    font-size: 10px;
    line-height: 12px;
    font-weight: 700;
    color: #FF6915;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
  }

  &__item:last-child {
    margin-bottom: 0;
  }

  &__item .icon {
    margin-right: 4px;
  }
}