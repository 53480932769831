/* .list-cards */
//@media ($xs) { /* .list-cards $xs */ }
//@media ($sm) { /* .list-cards $sm */ }
//@media ($md) { /* .list-cards $md */ }
//@media ($lg) { /* .list-cards $lg */ }
//@media ($xl) { /* .list-cards $xl */ }
//@media ($xxl) { /* .list-cards $xxl */ }

.list-cards {
  margin-bottom: 40px;

  @media ($lg) {
    margin-bottom: 72px;
  }

  &__item {
    padding-bottom: 8px;
  }

  &__item.opened {
    display: none;
  }

  .all-products {
    display: none;
  }

  .all-products.opened {
    display: block;
  }
}