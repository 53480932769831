/* .navigation */
//@media ($xs) { /* .navigation $xs */ }
//@media ($sm) { /* .navigation $sm */ }
//@media ($md) { /* .navigation $md */ }
@media ($lg) { /* .navigation $lg */ }
//@media ($xl) { /* .navigation $xl */ }
//@media ($xxl) { /* .navigation $xxl */ }

.navigation {
  font-family: $ff_third;
  font-size: 17px;
  //padding: 16px 0;

  @media ($lg) {
    padding: 4px 0;
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style-type: none;

    @media ($lg) {
      display: block;
    }
  }

  &__item {
    padding: 12px 16px;

    @media ($lg) {
      display: inline-block;
      vertical-align: middle;
      margin-right: 20px;
      padding: 14px 0 13px;
    }
  }

  &__link {
    color: $c_text;
    position: relative;
    
    @media ($lg) {
      color: #0F1011;
      font-weight: 700;
    }
  }
  &__link:after {
    content: "";
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 0;
    height: 17px;
    transition: width ease-in-out 200ms;
    background-color: #FFE55B;
  }
  &__link:hover {
    text-decoration: none;
  }
  &__link:hover:after {
    width: 100%;
  }
}